@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.gui-toast-group {
  position: fixed;
  z-index: 9999;
  inset-block-end: 0;
  inset-inline: 0;
  padding-block-end: 5vh;
  display: grid;
  justify-items: center;
  justify-content: center;
  gap: 1vh;
  pointer-events: none;
}

.gui-toast {
  max-inline-size: min(25ch, 90vw);
  border: 1px solid #4fccc4;
  border-radius: 6px;
  padding: 12px;
  padding-block: 0.5ch;
  padding-inline: 1ch;
  font-size: 1rem;
  color: black;
  background-color: white;
  --_duration: 3s;
  --_travel-distance: 0;
  will-change: transform;
  animation: fade-in 0.3s ease, slide-in 0.3s ease, fade-out 0.3s ease var(--_duration);
}

@media (prefers-color-scheme: dark) {
  .gui-toast {
    color: white;
    --_bg-lightness: 20%;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .gui-toast {
    --_travel-distance: 5vh;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(var(--_travel-distance, 10px));
  }
}
